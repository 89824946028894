import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import reset from 'styled-reset'
import { createGlobalStyle, styled } from 'styled-components';
import TopNav from './TopNav';
import About from './About';
import FixPara from './FixPara';
import Contact from './Contact';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  h1, h2, h3, h4, h5, h6, p, a, li {
    font-family: 'Montserrat', sans-serif;
  }
  
  h2 {
    font-size: 88px;
    margin: 0;
    color: black;
    margin-bottom: 12px;
    font-weight: 500;
    text-transform: lowercase;
  }

  p {
    font-size: 24px;
    margin: 0;
    color: black;
    margin-bottom: 24px;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: black; 

    &:hover {
      color: grey;
    }
  }
`

export const ContentContainer = styled.div`
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
  margin-top: 150px;
`;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <TopNav />
    <Home />
    <About />
    <FixPara $bgImage={'https://abihill.com/images/zigzag.png'} />
    <Contact />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
