import React from "react";
import styled from 'styled-components';

const Nav = styled.nav`
    display: flex;
    background: black;
    width: 100%;
    padding: 10px;
    position: fixed;

    a {
        color: white;
        text-decoration: none;    
        padding: 10px;
    }

    img {
        height: 25px;
        width: 25px;
    }
`;

const TopNav = () => {
    return (
        <>
        <Nav>
            <a href="#contact"><img src="https://www.abihill.com/images/logo-white.gif" alt="Abi Martin-Hill Portfolio Logo"/></a>
            <a href="#home">about.</a>
            <a href="#contact">contact.</a>
            <a href="https://blog.abimartinhill.com/">blog.</a>
        </Nav>
        </>
    );
    }

export default TopNav;