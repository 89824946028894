import React from "react";
import styled from 'styled-components';
import { GetYear } from "./utils/GetDate";
import { ContentContainer } from './index';

const Container = styled.section`
    height: 900px;
    background-color: black;
    overflow: hidden;
    position: relative;

    h2, p, li {
        color: white;
    }

    li {
        margin-bottom: 12px;
    }

    img {
        width: 25px;
    }
`;

const Copyright = styled.p`
    color: white;
    font-size: 14px;
    position: absolute;
    right: 24px;
    bottom: 24px;
`;

const Contact = () => {
    return (
        <Container>
            <ContentContainer>
            <img src="https://www.abihill.com/images/logo-white.gif" alt="Abi Martin-Hill Portfolio Logo" />
            <h2>Contact.</h2>
            <ul>
                <li>Github</li>
                <li>LinkedIn</li>
                <li>Blog</li>
            </ul>
            </ContentContainer>

            <Copyright>	&copy; Abi Martin-Hill. {GetYear()}</Copyright>
        </Container>
    );
}   

export default Contact;