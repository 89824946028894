import React from "react";
import styled from 'styled-components';
import { ContentContainer } from './index';

const Container = styled.section`
    padding: 48px 48px 164px;
    background-color: white;
    overflow: hidden;

    h3 {
        margin-bottom: 24px;
    }

    h4 {
        margin-bottom: 24px;
        font-size: 24px;
    }

    a {
        text-decoration: underline;
    }

    img {
        width: 25px;
    }
`;

const About = () => {
    return (
        <Container>
            <ContentContainer>
                <img src="https://www.abihill.com/images/logo.gif" alt="Abi Martin-Hill Portfolio Logo" />    
                <h2>About.</h2>
                <h3>Software Engineer | London</h3>

                <h4>A bit about me...</h4>
                <p>Hello, my name is Abi. I'm a Front End Software Engineer & Digital Designer from London.</p>
                <p>Previously, I had a 7-year career in Digital Marketing within the eCommerce and Travel Sector for brands such as Cath Kidston and BuyAGift (Red Letter Days). I had a passion for programming outside of work, so I decided to move have a small career change in to Web Development back in 2018.</p>
                <p>Currently, I'm a Front End Software Engineer at <a href="https://www.mixcloud.com/" rel="noreferrer" target="_blank">Mixcloud</a>. Where I get to build features for incredible DJs and Producers. 🤘🏼</p>

                <h4>Career highlights since moving in to Software Engineering...</h4>
                <ul>
                    <li><p>Working on large-scale projects for some of the biggest tech giants including Google.</p></li>
                    <li><p>Being shortlisted on the Makers, Google StartUps, and Computer Weekly’s <a target="_blank" rel="noreferrer" href="https://blog.makersacademy.com/shortlist-announced-for-the-2020-women-in-software-power-list-82fe44253eab">Women in Software Powerlist 2020</a></p></li>
                </ul>
               
                <h4>Outside of work...</h4>
                <p>Outside of work I run a queer online magazine called <a href="https://www.nonchalantmagazine.com/" rel="noreferrer" target="_blank">Nonchalant Magazine</a> which I founded back in 2016. After gaining a lot of followers and attention, it progressed in to a business in 2017 and now has a small team of writers, videographers, and creatives that drink too much coffee.</p>
                <p>As the Founding Director, I take huge pride in our interviews section that showcases an incredible range of inspiring and courageous women within the queer community including Comedian & BBC 'Mock the Week' Star Zoe Lyons, ITN 5 News broadcaster Minnie Stephenson, Songwriter, Eastenders actress Heather Peace, and TV presenter Chelcee Grimes, and Instagram & Love Island Star - Megan Barton Hanson.</p>
            </ContentContainer>
        </Container>
    );
}   

export default About;