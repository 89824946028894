import React from 'react';
import styled from 'styled-components';

const FixBackground = styled.div`
    background-image: url(https://www.abihill.com/images/abi-hill-logo-glitch-white.gif);
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 250px;
    min-height: 40vh;
    background-attachment: fixed;
    background-position-y: 100px;
`;

const Home = () => {
  return (
    <>
      <FixBackground></FixBackground>
    </>
    
  );
}

export default Home;
