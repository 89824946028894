import React from "react";
import styled from "styled-components";

const Para = styled.div`
    background-image: ${({bgImage}) => `url(${bgImage})`};
    background-position: center;
    background-size: 400px;
    min-height: 400px;
    background-attachment: fixed;
`;

const FixPara = ({ $bgImage }) => {
    return (
        <Para bgImage={$bgImage}></Para>
    );
}   

export default FixPara; 